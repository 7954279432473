import { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import { useHistory } from 'react-router-dom';

// import { MdCloudDownload } from 'react-icons/md';

import { SubmitHandler, useForm } from 'react-hook-form';

import { updateCacheById } from 'graphql/apollo/cache';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

// import { Button } from 'ui';
import { ModalProps } from 'ui/models/overlay';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import AddButton from 'dashboard/components/dashboard/AddButton';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { ContactGroupsList } from 'contactGroups/models/contactGroups';
import ContactGroupsTable from 'contactGroups/components/table/ContactGroupsTable';
import ContactGroupsAlertModal from 'contactGroups/components/modal/ContactGroupsAlertModal';
import ContactGroupsExportModal from 'contactGroups/components/modal/ContactGroupsExportModal';

import {
  contactGroupsTypename,
  CONTACT_GROUPS_QUERY,
} from 'contactGroups/graphql/contactGroupsQuery';

import ContactGroupsFormFilters, {
  FormFilters,
} from 'contactGroups/components/form/ContactGroupsFormFilters';

import {
  ContactGroupDeleteMutationVariables,
  CONTACT_GROUP_DELETE_MUTATION,
} from 'contactGroups/graphql/contactGroupsDeleteMutation';

type State = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  contactGroups: ContactGroupsList[];
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Grupo & Contatos',
};

const DELETE_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Houve um erro ao tentar excluir Grupo & Contatos',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso',
  variant: 'primary',
  text: 'Grupo & Contatos excluídos com sucesso',
};

const EXPORT_SUCCESS_TOAST: ToastProps = {
  variant: 'primary',
  title: 'Sucesso!',
  text: 'Sucesso ao baixar de Grupo & Contatos',
};

const EXPORT_MODAL: ModalProps = {
  variant: 'primary',
  text: 'Selecione o que você quer baixar:',
  title: 'Baixar relatório de Grupo & Contatos',
};

const ALERT_TOAST: ModalProps = {
  variant: 'alert',
  title: 'Atenção!',
  text: 'Caso nenhum filtro seja selecionado, será exportado toda a base de dados dessa tela. Deseja continuar mesmo assim?',
};

const DASHBOARD_TITLE = 'Grupo & Contatos';

const DASHBOARD_CREATE_ROUTE = '/dashboard/contact-groups/create';

export default function ListContactGroupsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [exportState, setExportState] = useState({
    show: false,
    isActive: false,
  });
  // const [hasActiveFilters, setHasActiveFilters] = useState(false);

  const [currentContactGroupId, setCurrentContactGroupId] = useState<number>(0);

  const [state, setState] = useState<State>({
    contactGroups: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const { control, register, handleSubmit } = useForm<FormFilters>();

  const [contactGroupsDeleteMutation, { loading: contactGroupsDeleteLoading }] =
    useMutation<ContactGroupDeleteMutationVariables>(CONTACT_GROUP_DELETE_MUTATION, {
      onError() {
        addToast(DELETE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(DELETE_SUCCESS_TOAST);
      },
    });

  const [
    contactGroupList,
    {
      data: contactGroupsData,
      error: contactGroupsError,
      loading: contactGroupsLoading,
      refetch: contactGroupsRefetch,
    },
  ] = useLazyQuery(CONTACT_GROUPS_QUERY, INITIAL_QUERY_STATE_CONFIG);

  useEffect(() => {
    contactGroupList();
  }, [contactGroupList]);

  const hasError = contactGroupsError;

  useEffect(() => {
    if (hasError) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, hasError]);

  const isLoading = contactGroupsLoading || contactGroupsDeleteLoading;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (contactGroupsData) {
      setState({
        contactGroups: contactGroupsData.contactGroups.entries,
        hasNextPage: !!contactGroupsData.contactGroups.afterCursor,
        hasPreviousPage: !!contactGroupsData.contactGroups.beforeCursor,
      });
    }

    closeLoading();
  }, [contactGroupsData, isLoading, closeLoading, showLoading]);

  const onClickEditButton = (id: number) =>
    push(`/dashboard/contact-groups/${id}/edit`);

  const onClickAddButton = () => push(DASHBOARD_CREATE_ROUTE);

  const onClickRemoveButton = (id: number) => {
    setCurrentContactGroupId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    contactGroupsDeleteMutation({
      variables: { id: currentContactGroupId },
      update(cache) {
        updateCacheById(cache, currentContactGroupId, contactGroupsTypename);
      },
    });
  };

  const handleClickNext = () => {
    contactGroupsRefetch &&
      contactGroupsRefetch({
        before: null,
        after: contactGroupsData.contactGroups.afterCursor,
      });
  };

  const handleClickBefore = () => {
    contactGroupsRefetch &&
      contactGroupsRefetch({
        after: null,
        before: contactGroupsData.contactGroups.beforeCursor,
      });
  };

  const onClickContinue = () => {
    modalCloseConfirm();
    setExportState({ show: true, isActive: false });
    addToast(EXPORT_SUCCESS_TOAST);
  };

  const onClickCloseModal = () => {
    modalCloseConfirm();
    setExportState({ show: false, isActive: false });
  };

  const onClickDangerContinue = () => {
    modalCloseConfirm();
    setExportState({ show: true, isActive: true });
    modalShowConfirm();
  };

  // const handleExport = () => {
  //   modalShowConfirm();

  //   hasActiveFilters
  //     ? setExportState({ show: true, isActive: true })
  //     : setExportState({ show: false, isActive: true });
  // };

  const handleHasActiveFilters = (hasActiveFilters: number) => {
    // setHasActiveFilters(!!hasActiveFilters);
  };

  const onSubmit: SubmitHandler<FormFilters> = (contactGroups) => {
    contactGroupsRefetch({
      filters: Object.fromEntries(
        Object.entries(contactGroups).filter(([, value]) => !!value)
      ),
    });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title={DASHBOARD_TITLE}>
          <AddButton onClick={onClickAddButton} label="Grupo & Contatos" />
        </DashboardMainHeaderForm>
      }
    >
      <div className="flex w-full justify-between">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContactGroupsFormFilters
            control={control}
            register={register}
            isLoading={isLoading}
            handleHasActiveFilters={handleHasActiveFilters}
          />
        </form>

        {/* <div className="flex items-end justify-end pb-6">
          <Button
            size="sm"
            variant="secondaryGray"
            className="justify-end"
            onClick={handleExport}
          >
            <MdCloudDownload size={20} /> CSV
          </Button>
        </div> */}
      </div>

      <div className="rounded-lg">
        <ContactGroupsTable
          contactGroups={state.contactGroups}
          onClickEditButton={onClickEditButton}
          onClickRemoveButton={onClickRemoveButton}
        />

        <Pagination
          onNextClick={handleClickNext}
          disableNext={!state.hasNextPage}
          onPreviousClick={handleClickBefore}
          disableBefore={!state.hasPreviousPage}
        />

        <ConfirmOverlay
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={() => closeConfirm()}
          title="Desativação de Grupo & Contatos"
          text={
            'Tem certeza que deseja desativar esse Grupo & Contatos? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida.'
          }
        />

        {exportState.show && (
          <ContactGroupsExportModal
            modal={EXPORT_MODAL}
            ModalOverlay={ModalOverlay}
            onClickContinue={onClickContinue}
            onClickCloseModal={onClickCloseModal}
          />
        )}

        {exportState.isActive && !exportState.show && (
          <ContactGroupsAlertModal
            alert={ALERT_TOAST}
            ModalOverlay={ModalOverlay}
            onClickCloseModal={onClickCloseModal}
            onClickContinue={onClickDangerContinue}
          />
        )}
      </div>

      <LoadingOverlay />
    </Dashboard>
  );
}
