import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { unMaskFormFields } from 'utils/unmask';
import { dateWithoutTimezone } from 'utils/form';

import { MdCloudDownload } from 'react-icons/md';

import { SubmitHandler, useForm } from 'react-hook-form';

import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

import { Button } from 'ui';
import { ModalProps } from 'ui/models/overlay';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { FinancialAccountPayouts } from 'financialAccountPayouts/models/financialAccountPayouts';
import { FINANCIAL_ACCOUNT_PAYOUTS_QUERY } from 'financialAccountPayouts/graphql/financialAccountPayoutsQuery';
import FinancialAccountPayoutsTable from 'financialAccountPayouts/components/table/FinancialAccountPayoutsTable';
import FinancialAccountPayoutsExportModal from 'financialAccountPayouts/components/modal/FinancialAccountPayoutsExportModal';
import FinancialAccountPayoutsCounterItem from 'financialAccountPayouts/components/counter/FinancialAccountPayoutsCounterItem';
import FinancialAccountPayoutsFormFilters, {
  FormFilters,
} from 'financialAccountPayouts/components/form/FinancialAccountPayoutsFormFilters';

import {
  FinancialAccountPayoutsCounterList,
  FINANCIAL_ACCOUNT_PAYOUTS_COUNTER_QUERY,
} from 'financialAccountPayouts/graphql/financialAccountPayoutsCounterQuery';

type State = {
  financialAccountPayouts: FinancialAccountPayouts[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Transferências',
};

const EXPORT_SUCCESS_TOAST: ToastProps = {
  variant: 'primary',
  title: 'Sucesso!',
  text: 'Sucesso ao baixar Relatório Financeiro',
};

const EXPORT_MODAL: ModalProps = {
  variant: 'primary',
  title: 'Baixar relatório de Transferência',
  text: 'Selecione o que você quer baixar:',
};

const DASHBOARD_TITLE = 'Transferências';

export default function ListFinancialAccountPayoutsPage() {
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [state, setState] = useState<State>({
    financialAccountPayouts: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFilters>();

  const [FinancialAccountPayoutsList, { data, loading, error, refetch }] =
    useLazyQuery(FINANCIAL_ACCOUNT_PAYOUTS_QUERY, INITIAL_QUERY_STATE_CONFIG);

  useEffect(() => {
    FinancialAccountPayoutsList();
  }, [FinancialAccountPayoutsList]);

  const [
    financialAccountPayoutsCounterSelect,
    {
      data: financialAccountPayoutsCounter,
      loading: loadingFinancialAccountPayoutsCounter,
      refetch: refetchFinancialAccountPayoutsCounter,
    },
  ] = useLazyQuery<FinancialAccountPayoutsCounterList>(
    FINANCIAL_ACCOUNT_PAYOUTS_COUNTER_QUERY
  );

  useEffect(() => {
    refetchFinancialAccountPayoutsCounter && refetchFinancialAccountPayoutsCounter();

    financialAccountPayoutsCounterSelect();
  }, [financialAccountPayoutsCounterSelect, refetchFinancialAccountPayoutsCounter]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || loadingFinancialAccountPayoutsCounter;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (data) {
      setState({
        hasNextPage: !!data.financialAccountPayouts.afterCursor,
        hasPreviousPage: !!data.financialAccountPayouts.beforeCursor,
        financialAccountPayouts: data.financialAccountPayouts.entries,
      });
    }

    closeLoading();
  }, [data, isLoading, closeLoading, showLoading]);

  const onClickContinue = () => {
    modalCloseConfirm();
    addToast(EXPORT_SUCCESS_TOAST);
  };

  const handleClickNext = () => {
    refetch &&
      refetch({
        before: null,
        after: data.financialAccountPayouts.afterCursor,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.financialAccountPayouts.beforeCursor,
      });
  };

  const onSubmit: SubmitHandler<FormFilters> = (financialAccountPayoutsInput) => {
    const {
      financialAccountPayoutStatusDone,
      financialAccountPayoutStatusCancel,
      financialAccountPayoutScheduleDate,
      financialAccountPayoutEffectiveDate,
      financialAccountPayoutStatusPending,
    } = financialAccountPayoutsInput;

    const FINANCIAL_ACCOUNT_PAYOUT_STATUS = {
      financialAccountPayoutStatusDone,
      financialAccountPayoutStatusCancel,
      financialAccountPayoutStatusPending,
    };

    delete financialAccountPayoutsInput.financialAccountPayoutStatusDone;
    delete financialAccountPayoutsInput.financialAccountPayoutStatusCancel;
    delete financialAccountPayoutsInput.financialAccountPayoutScheduleDate;
    delete financialAccountPayoutsInput.financialAccountPayoutStatusPending;
    delete financialAccountPayoutsInput.financialAccountPayoutEffectiveDate;

    Object.entries(FINANCIAL_ACCOUNT_PAYOUT_STATUS).forEach(([key, value]) => {
      if (!value) {
        delete FINANCIAL_ACCOUNT_PAYOUT_STATUS[
          key as keyof typeof FINANCIAL_ACCOUNT_PAYOUT_STATUS
        ];
      }
    });

    Object.assign(financialAccountPayoutsInput, {
      financialAccountPayoutStatus: FINANCIAL_ACCOUNT_PAYOUT_STATUS,
    });

    const isoDatesWithoutTimezone = [
      financialAccountPayoutEffectiveDate,
      financialAccountPayoutScheduleDate,
    ].map((dates) => dates?.map((date) => dateWithoutTimezone(date).toISOString()));

    Object.assign(financialAccountPayoutsInput, {
      ...unMaskFormFields(financialAccountPayoutsInput),
      financialAccountPayoutEffectiveDateFrom: isoDatesWithoutTimezone[0]?.[0],
      financialAccountPayoutEffectiveDateTo: isoDatesWithoutTimezone[0]?.[1],
      financialAccountPayoutScheduleDateFrom: isoDatesWithoutTimezone[1]?.[0],
      financialAccountPayoutScheduleDateTo: isoDatesWithoutTimezone[1]?.[1],
    });

    refetch &&
      refetch({
        filters: Object.fromEntries(
          Object.entries(financialAccountPayoutsInput).filter(([, value]) => !!value)
        ),
      });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title={DASHBOARD_TITLE}>
          <Button
            size="sm"
            variant="secondaryGray"
            className="justify-end"
            onClick={modalShowConfirm}
          >
            <MdCloudDownload size={20} /> Relatório de Transferência
          </Button>
        </DashboardMainHeaderForm>
      }
    >
      <FinancialAccountPayoutsCounterItem
        financialAccountPayoutsCounter={
          financialAccountPayoutsCounter?.financialAccountPayoutsCounter
        }
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FinancialAccountPayoutsFormFilters
          errors={errors}
          control={control}
          setValue={setValue}
          register={register}
          isLoading={isLoading}
        />
      </form>

      <div className="rounded-lg">
        <FinancialAccountPayoutsTable
          financialAccountPayouts={state.financialAccountPayouts}
        />

        <Pagination
          onNextClick={handleClickNext}
          disableNext={!state.hasNextPage}
          onPreviousClick={handleClickBefore}
          disableBefore={!state.hasPreviousPage}
        />
      </div>

      <FinancialAccountPayoutsExportModal
        modal={EXPORT_MODAL}
        ModalOverlay={ModalOverlay}
        onClickContinue={onClickContinue}
        onClickCloseModal={modalCloseConfirm}
      />

      <LoadingOverlay />
    </Dashboard>
  );
}
