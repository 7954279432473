export const EconomyReportPrintOptions = (document: Document) => {
  document.getElementById('economy-report-title')!.style.marginTop = '-1.5rem';

  document.getElementById('economy-report-billing-info')!.style.padding =
    '1.25rem 2rem 1.5rem 2rem';

  document.getElementById('economy-report-notice-title')!.style.marginTop = '-1rem';

  document.getElementById('economy-report-notice-message')!.style.marginTop =
    '-1rem';

  document.getElementById('economy-report-credits-legend')!.style.marginTop =
    '-.35rem';

  document.getElementById('economy-report-credits-legend')!.style.paddingBottom =
    '.5rem';

  document.getElementById('economy-report-generation-unit-name')!.style.marginTop =
    '-.5rem';

  document.getElementById('economy-report-bill-notice')!.style.marginTop = '-.5rem';

  document.getElementById('economy-report-bill-notice')!.style.marginBottom =
    '.5rem';

  document.getElementById('economy-report-bill-value-global')!.style.marginBottom =
    '.5rem';

  document.getElementById('economy-report-bill-due-date')!.style.margin =
    '-.25rem 0 .25rem 0';

  document?.querySelectorAll('#economy-report-credits-title').forEach((element) => {
    element.setAttribute(
      'style',
      element
        .getAttribute('style')
        ?.concat('margin: -.5rem 0 .25rem 0 !important') || ''
    );
  });

  document?.querySelectorAll('#economy-report-credits-value').forEach((element) => {
    element.setAttribute(
      'style',
      element.getAttribute('style')?.concat('margin-top: -1.15rem!important') || ''
    );
  });

  document?.querySelectorAll('#economy-report-bill-title').forEach((element) => {
    element.setAttribute(
      'style',
      element
        .getAttribute('style')
        ?.concat('margin: -.5rem 0 .25rem 0 !important') || ''
    );
  });

  document?.querySelectorAll('#economy-report-bill-value').forEach((element) => {
    element.setAttribute(
      'style',
      element
        .getAttribute('style')
        ?.concat('margin: -1.25rem 0 .25rem 0 !important') || ''
    );
  });

  document?.querySelectorAll('#economy-report-bill-month').forEach((element) => {
    element.setAttribute(
      'style',
      element
        .getAttribute('style')
        ?.concat('margin: -.35rem 0 .5rem 0 !important') || ''
    );
  });

  document
    ?.querySelectorAll('#economy-report-bill-month-value')
    .forEach((element) => {
      element.setAttribute(
        'style',
        element
          .getAttribute('style')
          ?.concat('margin: -.35rem 0 .5rem 0 !important') || ''
      );
    });
};

export const EconomyReportPrintOptionsNewLayout = (document: Document) => {
  // document.getElementById('economy-report-title')!.style.marginTop = '-1.5rem';
  document.getElementById('economy-report-billing-info')!.style.paddingBottom =
    '2rem';

  document.getElementById('economy-report-billing-info-v2')!.style.paddingBottom =
    '2.25rem';

  document.getElementById('economy-report-notice-message')!.style.paddingBottom =
    '1.75rem';

  const element = Array.from(
    document.getElementsByClassName(
      'economy-report-bill'
    ) as HTMLCollectionOf<HTMLElement>
  );

  element.forEach((e) => {
    e.style.paddingBottom = '1rem';
  });

  document.getElementById('economy-report-bill-total')!.style.paddingBottom =
    '1.25rem';

  document.getElementById('economy-report-bill-total-v2')!.style.paddingBottom =
    '1.25rem';
};
