import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export default function NexLogo({ style, className }: Props) {
  return (
    <div
      className={className || 'flex items-center'}
      style={
        style || {
          width: '75px',
        }
      }
    >
      <svg
        width="85"
        height="29"
        viewBox="0 0 85 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.64717 0.0417009C4.84889 0.0659674 5.04555 0.117668 5.23076 0.195103C5.41354 0.273296 5.58224 0.376754 5.731 0.501895C5.92441 0.669581 6.10544 0.848947 6.27291 1.03879L21.2399 18.4495C21.181 17.8932 21.1376 17.3527 21.1098 16.8281C21.0821 16.3035 21.0676 15.8095 21.0665 15.3462V0.00642109H27.5128V28.9987H23.706C23.2173 29.0115 22.7311 28.93 22.2787 28.7594C21.831 28.5577 21.4444 28.2573 21.1532 27.885L6.29459 10.6124C6.33794 11.1165 6.37353 11.611 6.40132 12.0957C6.43134 12.5805 6.44468 13.0345 6.44468 13.4579V28.9926H0V0.000282682H3.85014C4.11652 -0.00219705 4.38278 0.01164 4.64717 0.0417009Z"
          fill="#333333"
        />
        <path
          d="M64.4767 14.0923L54.6837 0.00399409H62.0226C62.3837 -0.0163846 62.745 0.0414224 63.0773 0.172748C63.3268 0.310808 63.5323 0.506571 63.6732 0.740395L69.9709 10.5115C70.0421 10.3405 70.1254 10.174 70.2203 10.0129C70.314 9.85949 70.4195 9.6892 70.535 9.51584L76.0459 0.840113C76.1831 0.58895 76.3939 0.377731 76.6547 0.230072C76.9154 0.0824124 77.2158 0.00413621 77.5224 0.00399409H84.5532L74.6297 13.7946L84.7692 28.9982H77.4303C77.015 29.0156 76.6056 28.9037 76.2685 28.6807C75.9792 28.4758 75.7393 28.2183 75.5638 27.9243L69.2226 17.7743C69.122 18.0385 68.9906 18.2921 68.8309 18.5306L62.9249 27.9243C62.7444 28.2093 62.509 28.4619 62.2302 28.6699C61.923 28.8946 61.5382 29.011 61.1454 28.9982H54.282L64.4767 14.0923Z"
          fill="#333333"
        />
        <path
          d="M38.69 23.8245V16.9197H48.748V11.9422H38.69V5.1755H55.0256L51.3891 0H31.2307V29H50.9997L54.5753 23.8245H38.69Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
}
