import { useMemo, useEffect, useState, useCallback } from 'react';
import omit from 'lodash/omit';
import {
  Control,
  Controller,
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import RadioField from 'ui/form/RadioField';
import CheckboxField from 'ui/form/CheckboxField';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';

import { CommercialAgreementReceiptRuleType } from 'commercialAgreements/models/commercialAgreement';
import { Option } from 'ui/components/form/SelectInput';
import SelectField from 'ui/form/SelectField';
import TextField from 'ui/form/TextField';
import { FieldGroupLabel } from 'ui';
import {
  GenerationUnitEnergySourceTypes,
  GenerationUnitSelectModel,
} from 'generationUnits/models/generationUnit';
import { uniq } from 'lodash';

type Field =
  | 'commercialAgreementStartValidity'
  | 'commercialAgreementHasPisCofins'
  | 'commercialAgreementHasExtraRemuneration'
  | 'commercialAgreementReceiptRule'
  | 'commercialAgreementLeaseValue'
  | 'commercialAgreementOemValue'
  | 'commercialAgreementHasOem'
  | 'commercialAgreementDiscountMax'
  | 'commercialAgreementDiscountMin'
  | 'commercialAgreementBaseCostPerCreditIcms'
  | 'commercialAgreementBaseCostPerCredit'
  | 'generationUnitId';

export const FORM_FIELDS: Field[] = [
  'commercialAgreementStartValidity',
  'commercialAgreementHasPisCofins',
  'commercialAgreementHasExtraRemuneration',
  'commercialAgreementReceiptRule',
  'commercialAgreementLeaseValue',
  'commercialAgreementOemValue',
  'commercialAgreementHasOem',
  'commercialAgreementDiscountMax',
  'commercialAgreementDiscountMin',
  'commercialAgreementBaseCostPerCreditIcms',
  'commercialAgreementBaseCostPerCredit',
  'generationUnitId',
];

export type FormFields = {
  commercialAgreementStartValidity: Date;
  commercialAgreementHasPisCofins: boolean;
  commercialAgreementHasExtraRemuneration: boolean;
  commercialAgreementReceiptRule: CommercialAgreementReceiptRuleType;
  commercialAgreementLeaseValue: string;
  commercialAgreementOemValue: string;
  commercialAgreementHasOem: boolean;
  commercialAgreementDiscountMax: string;
  commercialAgreementDiscountMin: string;
  commercialAgreementBaseCostPerCreditIcms: string;
  commercialAgreementBaseCostPerCredit: string;
  generationUnitId: number;
};

export type FormErrors = {
  commercialAgreementStartValidity?: FieldError;
  commercialAgreementHasPisCofins?: FieldError;
  commercialAgreementHasExtraRemuneration?: FieldError;
  commercialAgreementReceiptRule?: FieldError;
  commercialAgreementLeaseValue?: FieldError;
  commercialAgreementOemValue?: FieldError;
  commercialAgreementHasOem?: FieldError;
  commercialAgreementDiscountMax?: FieldError;
  commercialAgreementDiscountMin?: FieldError;
  commercialAgreementBaseCostPerCreditIcms?: FieldError;
  commercialAgreementBaseCostPerCredit?: FieldError;
  generationUnitId?: FieldError;
};

export default function CommercialAgreementsFormFields({
  generationUnits,
  register,
  control,
  disableFields,
  errors,
  clearErrors,
  setValue,
  resetField,
}: {
  generationUnits?: GenerationUnitSelectModel[];
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
  disableFields?: boolean;
  errors: FormErrors;
  setValue: UseFormSetValue<FormFields>;
  resetField: UseFormResetField<FormFields>;
  clearErrors: UseFormClearErrors<FormFields>;
}) {
  const [
    commercialAgreementHasExtraRemuneration,
    setCommercialAgreementHasExtraRemuneration,
  ] = useState(false);

  const [generationUnitsOptions, setGenerationUnitsOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setGenerationUnitsOptions(
        generationUnits?.map((generationUnits) => ({
          key: generationUnits.id,
          value: generationUnits.generationUnitLegalName,
        })) || []
      ),
    [generationUnits]
  );

  const [generationUnitId, commercialAgreementHasOem] = useWatch({
    control,
    name: ['generationUnitId', 'commercialAgreementHasOem'],
  });

  useMemo(() => {
    generationUnits
      ?.filter((generationUnit) => generationUnit.id === generationUnitId)
      .forEach((generationUnit) => {
        setCommercialAgreementHasExtraRemuneration(
          generationUnit.generationUnitEnergySource ===
            GenerationUnitEnergySourceTypes.UFV
        );
      });
  }, [generationUnitId, generationUnits]);

  const disabledFields = useMemo(() => {
    const disabledMap = {
      commercialAgreementHasExtraRemuneration,
      commercialAgreementOemValue: commercialAgreementHasOem,
    };

    return uniq(
      Object.entries(disabledMap)
        .filter(([_key, value]) => value)
        .map(([key]) => key)
    );
  }, [commercialAgreementHasExtraRemuneration, commercialAgreementHasOem]);

  const isDisabled = useCallback(
    (field) => disabledFields.includes(field),
    [disabledFields]
  );

  useEffect(() => {
    if (commercialAgreementHasOem) {
      resetField('commercialAgreementOemValue');
    } else {
      setValue('commercialAgreementOemValue', '0');
      clearErrors('commercialAgreementOemValue');
    }
  }, [clearErrors, commercialAgreementHasOem, resetField, setValue]);

  return (
    <div className="grid gap-8">
      <div className="grid grid-cols-10 gap-8">
        <div className="col-start-1 col-end-3">
          <Controller
            name="generationUnitId"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <SelectField
                {...omit(field, 'ref')}
                required
                error={errors.generationUnitId?.message}
                label="Unidade geradora"
                emptyOptionLabel="Nome da Usina"
                id="generationUnitId"
                options={generationUnitsOptions}
                disabled={disableFields}
              />
            )}
          />
        </div>
        <div className="col-start-3 col-end-5 mb-5">
          <Controller
            render={({ field }) => (
              <DatePickerFieldSingleView
                {...omit(field, 'ref')}
                required
                error={errors.commercialAgreementStartValidity?.message}
                id="commercialAgreementStartValidity"
                labelDate="Início da vigência"
                placeholder="Mês e ano"
                options={{ month: '2-digit', year: '2-digit' }}
                calendarWrapperClassName="z-10"
              />
            )}
            name="commercialAgreementStartValidity"
            control={control}
            rules={{ required: 'Campo obrigatório.' }}
          />
        </div>

        <div className="col-start-1 col-end-4">
          <Controller
            name="commercialAgreementReceiptRule"
            control={control}
            rules={{ required: 'Campo obrigatório.' }}
            render={({ field: { ref, value, onChange } }) => (
              <RadioField
                {...omit(ref, 'ref', 'value')}
                error={errors.commercialAgreementReceiptRule?.message}
                label="Políticas de recebimento"
                id="commercialAgreementReceiptRule"
                required
                options={[
                  {
                    label: 'Saque autom. semanal',
                    value: 'WEEKLY',
                  },
                  {
                    label: 'Saque autom. mensal',
                    value: 'MONTHLY',
                  },
                  {
                    label: 'Saque manual via painel do gateway',
                    value: 'MANUAL',
                  },
                ]}
                value={value}
                onChange={onChange}
                flexDirectionClassName="flex-col"
              />
            )}
          />
        </div>
        <div className="col-start-4 col-end-7 flex flex-col gap-4">
          <CheckboxField
            label="Opções de remunerações adicionais"
            inputLabel="Isenção de PIS/COFINS"
            {...register('commercialAgreementHasPisCofins')}
          />
          <CheckboxField
            inputLabel="Performance em horário ponta"
            {...register('commercialAgreementHasExtraRemuneration')}
            disabled={isDisabled('commercialAgreementHasExtraRemuneration')}
          />
        </div>

        <div className="col-start-1 col-end-3">
          <CheckboxField
            label="Opções de custo operacional"
            {...register('commercialAgreementHasOem')}
            inputLabel="Discriminação de O&M"
          />
        </div>
        <div className="col-start-3 col-end-5">
          <TextField
            mask="decimal"
            error={errors.commercialAgreementOemValue?.message}
            label="Percentual de O&M"
            {...register('commercialAgreementOemValue', {
              required: 'Campo obrigatório.',
            })}
            id="commercialAgreementOemValue"
            placeholder="Ex.: 20,00%"
            disabled={!isDisabled('commercialAgreementOemValue')}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <FieldGroupLabel>
          Custos base (preço por crédito pela locação)
        </FieldGroupLabel>
        <div className="grid grid-cols-12 gap-8">
          <div className="col-start-1 col-end-3">
            <TextField
              mask="decimalEightDigitsPrecision"
              error={errors.commercialAgreementBaseCostPerCreditIcms?.message}
              label="Com ICMS (R$/KWh)"
              id="commercialAgreementBaseCostPerCreditIcms"
              {...register('commercialAgreementBaseCostPerCreditIcms', {
                required: 'Campo obrigatório.',
              })}
              placeholder="Ex.: 0,123456"
            />
          </div>
          <div className="col-start-3 col-end-5">
            <TextField
              mask="decimalEightDigitsPrecision"
              error={errors.commercialAgreementBaseCostPerCredit?.message}
              label="Sem ICMS (R$/KWh)"
              id="commercialAgreementBaseCostPerCredit"
              {...register('commercialAgreementBaseCostPerCredit', {
                required: 'Campo obrigatório.',
              })}
              placeholder="Ex.: 0,123456"
            />
          </div>
          <div className="col-start-5 col-end-7">
            <TextField
              mask="currency"
              error={errors.commercialAgreementLeaseValue?.message}
              label="Aluguel do imóvel"
              id="commercialAgreementLeaseValue"
              {...register('commercialAgreementLeaseValue', {
                required: 'Campo obrigatório.',
              })}
              placeholder="Ex.: R$2.000,00"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <FieldGroupLabel>
          Faixa de desconto na tarifa mín. e máx permitidos
        </FieldGroupLabel>

        <div className="grid grid-cols-12 gap-8">
          <div className="col-start-1 col-end-3">
            <TextField
              mask="decimal"
              error={errors.commercialAgreementDiscountMin?.message}
              label="Desconto mín."
              id="commercialAgreementDiscountMin"
              {...register('commercialAgreementDiscountMin', {
                required: 'Campo obrigatório.',
              })}
              placeholder="Ex: 15,00%"
            />
          </div>
          <div className="col-start-3 col-end-5">
            <TextField
              mask="decimal"
              error={errors.commercialAgreementDiscountMax?.message}
              label="Desconto máx."
              id="commercialAgreementDiscountMax"
              {...register('commercialAgreementDiscountMax', {
                required: 'Campo obrigatório.',
              })}
              placeholder="Ex: 20,00%"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
