import { gql } from '@apollo/client';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

interface ConsumerUnitConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: ConsumerUnit[];
}

export interface ConsumerUnitsList {
  consumerUnits: ConsumerUnitConnection;
}

export const consumerUnitsTypename = 'ConsumerUnitConnection';

export const CONSUMER_UNITS_QUERY = gql`
  query consumerUnits(
    $after: String
    $before: String
    $pageSize: Int
    $filters: ConsumerUnitFilterOptions
    $maximumLimit: Int
  ) {
    consumerUnits(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        consumerUnitAddressCity
        consumerUnitAddressState
        consumerUnitAddressDistrict
        consumerUnitConsumptionGroupType
        consumerUnitPowerDistributionUnitIdentifier
        cooperative {
          cooperativeLegalName
        }
        cooperativeMember {
          id
          cooperativeMemberLegalName
          cooperativeMemberDocumentId
        }
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
        apportionmentCycleConsumerUnit {
          apportionmentCycleConsumerUnitPercentual
          generationUnit {
            generationUnitLegalName
          }
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
