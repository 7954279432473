import { gql } from '@apollo/client';

import { Charges } from 'charges/models/charges';

interface ChargesConnection {
  entries: Charges[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface ChargesList {
  charges: ChargesConnection;
}

export const chargesTypename = 'chargeConnection';

const MAX_SIZE = 10000;

export const CHARGES_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const CHARGES_QUERY = gql`
  query charges(
    $after: String
    $before: String
    $pageSize: Int
    $filters: ChargeFilterOptions
    $maximumLimit: Int
  ) {
    charges(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
      maximumLimit: $maximumLimit
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        chargeStatus
        chargeDueDate
        chargeInvoiceUrl
        chargeCreatedDate
        chargePaymentDate
        chargeClientPaymentDate
        chargeInterest
        chargeTotalPaid
        chargeTotalBilled
        chargeTotalCharged
        chargeAppTotalFees
        chargePaymentMethod
        chargeCooperativeTotalValue
        chargeGenerationUnitTotalValue
        chargeGenerationUnitOemTotalValue
        chargeCooperativeSubscriptionValue
        chargeGenerationUnitRealStateTotalValue
        chargeGenerationUnitEquipmentRentTotalValue
        cooperative {
          id
          cooperativeLegalName
        }
        generationUnit {
          id
          generationUnitLegalName
          generationUnitEnergySource
          generationUnitAddressState
          generationUnitPowerCapacity
          generationUnitCapacityFactorAverage
          powerDistributionUnitCredentialsUser
        }
        commercialAgreement {
          id
          commercialAgreementStartValidity
          commercialAgreementHasPisCofins
          commercialAgreementHasExtraRemuneration
          commercialAgreementReceiptRule
          commercialAgreementLeaseValue
          commercialAgreementOemValue
          commercialAgreementHasOem
          commercialAgreementDiscountMin
          commercialAgreementDiscountMax
          commercialAgreementBaseCostPerCreditIcms
          commercialAgreementBaseCostPerCredit
        }
        economyReport {
          economyReportExternalId
          flagEconomy
          economyValue
          energyEconomy
          economyReportUrl
          economyReferenceDate
        }
        powerDistributionUnitBillData {
          id
          billTotalValue
          billReferenceDate
        }
        cooperativeMember {
          cooperativeMemberLegalName
        }
        consumerUnit {
          id
          consumerUnitPowerDistributionUnitIdentifier
          consumerUnitConsumptionGroupType
        }
      }
    }
  }
`;
