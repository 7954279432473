import { gql } from '@apollo/client';

export const economyReportTypename = 'EconomyReport';

export const ECONOMY_REPORT_QUERY = gql`
  query economyReportData($id: ID!) {
    economyReportData(id: $id) {
      usedCredits
      economyValue
      chargeDueDate
      economyReports {
        economyReportExternalId
        flagEconomy
        economyValue
        energyEconomy
        economyReportUrl
        economyReferenceDate
      }
      totalEconomyValue
      billConsumedEnergyCredits {
        consumedEnergyCreditsTotal
      }
      receivedCredits
      usedCreditsOnPeak
      billReferenceDate
      chargeTotalCharged
      accumulatedCredits
      usedCreditsOffPeak
      receivedCreditsOnPeak
      receivedCreditsOffPeak
      generationUnitLegalName
      approximateChargeAmount
      consumerUnitAddressCity
      consumerUnitAddressState
      accumulatedCreditsOnPeak
      billTotalValue
      accumulatedCreditsOffPeak
      consumerUnitAddressStreet
      generationUnitAddressCity
      generationUnitAddressState
      cooperativeMemberLegalName
      cooperativeMemberDocumentId
      generationUnitAddressStreet
      generationUnitPowerCapacity
      tariffFlagModalityModalityFlag
      consumerUnitConsumptionGroupType
      generationUnitConsumptionGroupType
      apportionmentCycleConsumerUnitPercentual
      consumerUnitPowerDistributionUnitIdentifier
      billTotalValueAddChargeTotalCharged
    }
  }
`;
