import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export default function EconomyReportTree({ style, className }: Props) {
  return (
    <div
      className={className || 'flex items-center'}
      style={
        style || {
          width: '134px',
          height: '110px',
        }
      }
    >
      <svg
        width="168"
        height="138"
        viewBox="0 0 168 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93.3687 79.9322C84.4488 78.4231 67.8001 77.8648 62.3099 78.5013L56.6628 103.353L74.5452 116.242L97.604 112.901L120.663 96.6703C119.565 95.2384 116.428 91.6108 112.663 88.5557C107.957 84.7368 104.663 81.8428 93.3687 79.9322Z"
          fill="#333333"
        />
        <path d="M97 30L97 83" stroke="#333333" strokeWidth="3" />
        <path
          d="M27.6585 121.804L31.0083 122.037C46.8059 125.062 78.8253 131.075 80.5223 130.92C82.6437 130.727 104.401 123.675 112.348 116.46C120.296 109.245 132.242 101.057 129.474 95.8315C126.706 90.6059 117.174 95.1251 112.202 98.4177L110.374 99.8013C108.163 100.742 103.163 105.742 101.663 105.742C101.479 105.742 88.1628 108.242 82.1628 111.242C81.9941 111.327 81.0891 106.341 80.6628 106.242C79.3945 104.128 77.866 100.942 76.7373 100.008C79.1714 100.484 86.3593 101.501 92.6628 102.242C94.7119 102.483 98.1628 100.742 98.1628 100.742C98.1628 99.2422 99.7773 97.4711 98.6628 95.2422C98.1628 94.2422 95.0886 90.7422 94.1628 90.2422C90.9471 88.5054 89.6628 87.7422 88.6628 87.7422C85.694 84.7664 81.6628 84.2422 81.6628 84.2422C79.6628 83.0444 65.9749 75.3719 66.6599 76.5944C57.6202 72.2776 37.1699 65.0364 27.6861 70.6061C18.2023 76.1758 14.3057 79.4653 13.5429 80.4139C9.49566 80.7822 6.48883 84.3638 6.82693 88.4137L7.994 102.393C8.86732 112.854 17.1987 121.078 27.6585 121.804Z"
          fill="#E1E1E1"
        />
        <path
          d="M76 32.3984L78.9341 30.6441C84.1483 27.5266 90.9026 29.2262 94.0201 34.4404L95.7744 37.3745L92.8403 39.1288C87.6261 42.2464 80.8719 40.5467 77.7543 35.3325L76 32.3984Z"
          fill="#71F697"
        />
        <path
          d="M98 52.6465L99.1031 49.4108C101.064 43.6607 107.314 40.5885 113.064 42.5489L116.3 43.652L115.197 46.8877C113.236 52.6378 106.986 55.71 101.236 53.7496L98 52.6465Z"
          fill="#71F697"
        />
        <path
          d="M64.0414 108.072C65.6484 107.926 69.5449 106.963 72.2745 104.28C75.0041 101.597 76.2621 100.062 76.5499 99.6303C83.3252 101.651 97.2344 104.402 98.6689 99.2404C100.462 92.7881 95.4727 91.2134 84.5043 85.5168C75.7295 80.9594 68.9518 77.6675 66.6598 76.5912"
          stroke="#333333"
        />
        <path
          d="M144.954 12.4814L136.807 11.0422L134.04 2.91351L132.661 11.4195L124.875 14.3086L133.022 15.7478L135.79 23.8765L137.168 15.3705L144.954 12.4814Z"
          fill="#E1E1E1"
        />
        <path
          d="M45.4058 39.7102L33.9723 37.6904L30.0888 26.2827L28.1542 38.2199L17.2278 42.2745L28.6613 44.2943L32.5448 55.7021L34.4794 43.7648L45.4058 39.7102Z"
          fill="#E1E1E1"
        />
        <path
          d="M163.556 69.7821L155.952 68.4389L153.37 60.8525L152.083 68.791L144.817 71.4874L152.42 72.8306L155.003 80.4171L156.29 72.4785L163.556 69.7821Z"
          fill="#E1E1E1"
        />
        <path
          d="M96.6896 94.1472L95.4582 100.345C93.1579 99.9462 88.8405 98.3509 89.9735 95.1641C91.1065 91.9773 94.923 93.1583 96.6896 94.1472Z"
          fill="#333333"
        />
        <path
          d="M126.716 97.7025L129.073 100.328C128.966 100.608 128.508 101.475 127.522 102.701C126.536 103.927 125.306 104.864 124.814 105.179C124.487 105.141 123.719 104.629 123.262 102.886C122.806 101.142 125.375 98.7037 126.716 97.7025Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
}
