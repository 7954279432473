import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { useLocation } from 'react-router-dom';

import { MdCloudDownload } from 'react-icons/md';

import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

import { Button } from 'ui';
import { ModalProps } from 'ui/models/overlay';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { ChargesByFinancialAccountPayout } from '../models/chargesByFinancialAccountPayout';

import ChargesByFinancialAccountPayoutTable from '../components/table/ChargesByFinancialAccountPayoutTable';
import ChargesByFinancialAccountPayoutCounterItem from '../components/counter/ChargesByFinancialAccountPayoutCounterItem';
import FinancialAccountPayoutsExportModal from 'financialAccountPayouts/components/modal/FinancialAccountPayoutsExportModal';

import {
  ChargesByFinancialAccountPayoutCounterList,
  CHARGES_BY_FINANCIAL_ACCOUNT_PAYOUTS_COUNTER_QUERY,
} from '../graphql/chargesByFinancialAccountPayoutCounterQuery';

import { FINANCIAL_ACCOUNT_PAYOUT_INFOS_QUERY } from '../graphql/chargesByFinancialAccountPayoutQuery';
import { FinancialAccountPayoutId } from 'financialAccountPayouts/models/financialAccountPayouts';

type State = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  listChargesByFinancialAccountPayoutIdentifier: ChargesByFinancialAccountPayout[];
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Detalhes de Transferências',
};

const EXPORT_SUCCESS_TOAST: ToastProps = {
  variant: 'primary',
  title: 'Sucesso!',
  text: 'Sucesso ao baixar Relatório Financeiro',
};

const EXPORT_MODAL: ModalProps = {
  variant: 'primary',
  title: 'Baixar relatório de transferência',
  text: 'Selecione o que você quer baixar:',
};

const DASHBOARD_TITLE = 'Detalhe de transferências';

const FINANCIAL_ACCOUNT_PAYOUTS_ROUTE =
  '/dashboard/billing-cycle/financial-account-payouts';

const BREADCRUMB_PAGES: Page[] = [
  {
    current: false,
    name: 'Transferências',
    route: FINANCIAL_ACCOUNT_PAYOUTS_ROUTE,
  },
  {
    route: null,
    current: true,
    name: DASHBOARD_TITLE,
  },
];

type FinancialAccountPayoutFilterOptions = {
  financialAccountPayoutId: FinancialAccountPayoutId;
};

export default function ListChargesByFinancialAccountPayoutPage() {
  const location = useLocation();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [state, setState] = useState<State>({
    hasNextPage: false,
    hasPreviousPage: false,
    listChargesByFinancialAccountPayoutIdentifier: [],
  });

  const { financialAccountPayoutId } =
    (location.state as FinancialAccountPayoutFilterOptions) || {};

  const [ChargesByFinancialAccountPayoutList, { data, loading, error, refetch }] =
    useLazyQuery(FINANCIAL_ACCOUNT_PAYOUT_INFOS_QUERY, {
      ...INITIAL_QUERY_STATE_CONFIG,
      variables: {
        filters: {
          financial_account_payout_id: Number(financialAccountPayoutId),
        },
      } as any,
    });

  useEffect(() => {
    ChargesByFinancialAccountPayoutList();
  }, [ChargesByFinancialAccountPayoutList]);

  const [
    chargesByFinancialAccountPayoutCounterSelect,
    {
      data: chargesByFinancialAccountPayoutCounter,
      loading: loadingFinancialAccountPayoutsCounter,
      refetch: refetchFinancialAccountPayoutsCounter,
    },
  ] = useLazyQuery<ChargesByFinancialAccountPayoutCounterList>(
    CHARGES_BY_FINANCIAL_ACCOUNT_PAYOUTS_COUNTER_QUERY
  );

  useEffect(() => {
    refetchFinancialAccountPayoutsCounter && refetchFinancialAccountPayoutsCounter();

    chargesByFinancialAccountPayoutCounterSelect();
  }, [
    chargesByFinancialAccountPayoutCounterSelect,
    refetchFinancialAccountPayoutsCounter,
  ]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || loadingFinancialAccountPayoutsCounter;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (!!data) {
      setState({
        hasNextPage:
          !!data?.listChargesByFinancialAccountPayoutIdentifier?.afterCursor,
        hasPreviousPage:
          !!data?.listChargesByFinancialAccountPayoutIdentifier?.beforeCursor,
        listChargesByFinancialAccountPayoutIdentifier:
          data?.listChargesByFinancialAccountPayoutIdentifier?.entries,
      });
    }

    closeLoading();
  }, [data, isLoading, closeLoading, showLoading]);

  const onClickContinue = () => {
    modalCloseConfirm();
    addToast(EXPORT_SUCCESS_TOAST);
  };

  const handleClickNext = () => {
    refetch &&
      refetch({
        before: null,
        after: data.listChargesByFinancialAccountPayoutIdentifier.afterCursor,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.listChargesByFinancialAccountPayoutIdentifier.beforeCursor,
      });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm
          title={DASHBOARD_TITLE}
          breadcrumbPages={BREADCRUMB_PAGES}
        >
          <Button
            size="sm"
            variant="secondaryGray"
            className="justify-end"
            onClick={modalShowConfirm}
          >
            <MdCloudDownload size={20} /> Relatório de Transferência
          </Button>
        </DashboardMainHeaderForm>
      }
    >
      <ChargesByFinancialAccountPayoutCounterItem
        chargesByFinancialAccountPayoutCounter={
          chargesByFinancialAccountPayoutCounter?.chargesByFinancialAccountPayoutCounter
        }
      />

      <div className="rounded-lg">
        <ChargesByFinancialAccountPayoutTable
          chargesByFinancialAccountPayout={
            state.listChargesByFinancialAccountPayoutIdentifier
          }
        />

        <Pagination
          onNextClick={handleClickNext}
          disableNext={!state.hasNextPage}
          onPreviousClick={handleClickBefore}
          disableBefore={!state.hasPreviousPage}
        />
      </div>
      <FinancialAccountPayoutsExportModal
        modal={EXPORT_MODAL}
        ModalOverlay={ModalOverlay}
        onClickContinue={onClickContinue}
        onClickCloseModal={modalCloseConfirm}
      />
      <LoadingOverlay />
    </Dashboard>
  );
}
