import { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import NexLogo from 'assets/logo/NexLogo';

import { ALPHA_NUMERIC } from 'utils/unmask';
import {
  convertToBRL,
  convertToDecimal,
  formatDateToLocaleDateString,
  getPartsFromDate,
} from 'utils/form';

import { EconomyReportDataProps } from 'economyReport/pages/EconomyReport';

import EconomyReportGenerationImage from 'assets/background/economy-report/EconomyReportGenerationImage';
import EconomyReportGenerationCo2 from 'assets/background/economy-report/EconomyReportCo2';
import EconomyReportTree from 'assets/background/economy-report/EconomyReportTree';

import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';

type MonthAndYearProps = {
  date: string;
  spliter: string;
  options: Intl.DateTimeFormatOptions;
};

export default function FirstPageEconomyReport({
  economyReportData,
}: EconomyReportDataProps) {
  const {
    economyValue = '0',
    economyReports = [],
    totalEconomyValue = '0',
    chargeTotalCharged = '0',
    billConsumedEnergyCredits = {
      consumedEnergyCreditsTotal: '0',
    },
    generationUnitLegalName = '',
    billTotalValue = '0',
    generationUnitAddressCity = '-',
    generationUnitAddressState = '-',
    cooperativeMemberDocumentId = '-',
    generationUnitAddressStreet = '-',
    generationUnitPowerCapacity = '0',
    tariffFlagModalityModalityFlag = '-',
    billReferenceDate = new Date().getTime(),
    apportionmentCycleConsumerUnitPercentual = '0',
    consumerUnitPowerDistributionUnitIdentifier = '-',
  } = economyReportData || {};

  const getMonthAndYear = useCallback(
    ({ date, spliter, options }: MonthAndYearProps) =>
      `${
        getPartsFromDate(date, options).charAt(0).toUpperCase() +
        getPartsFromDate(date, options).slice(1).replace('.', '')
      }${spliter}${getPartsFromDate(date, {
        year: 'numeric',
        timeZone: 'UTC',
      })}`,
    []
  );

  const getTariffFlagModalityFlag = useCallback(
    () =>
      [
        {
          key: TariffFlagModalityModalityFlagType.GREEN,
          flag: 'Bandeira Verde',
          color: 'border-l-primary',
        },
        {
          key: TariffFlagModalityModalityFlagType.YELLOW,
          flag: 'Bandeira Amarela',
          color: 'border-l-yellow-500',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK,
          flag: 'Bandeira Vermelha PT1',
          color: 'border-l-red-a1',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK_TWO,
          flag: 'Bandeira Vermelha PT2',
          color: 'border-l-red-a1',
        },
      ].find(({ key }) => key === tariffFlagModalityModalityFlag),
    [tariffFlagModalityModalityFlag]
  );

  const HAS_CONSUMER_UNIT_INFO = useMemo(
    () => [
      {
        key: 'Unidade consumidora',
        value: consumerUnitPowerDistributionUnitIdentifier,
      },
      {
        key: 'Documento',
        value: cooperativeMemberDocumentId,
      },
    ],
    [cooperativeMemberDocumentId, consumerUnitPowerDistributionUnitIdentifier]
  );

  const HAS_GENERATION_UNIT_INFO = useMemo(
    () => [
      {
        key: 'Potência instalada:',
        value: +generationUnitPowerCapacity / 1000 + 'MW',
      },
      {
        key: 'Percentual alugado:',
        value: apportionmentCycleConsumerUnitPercentual + '% ALOCADO',
      },
      {
        key: 'Endereço:',
        value: `${generationUnitAddressStreet}, ${generationUnitAddressCity} - ${generationUnitAddressState}`,
      },
    ],
    [
      generationUnitAddressCity,
      generationUnitAddressState,
      generationUnitPowerCapacity,
      generationUnitAddressStreet,
      apportionmentCycleConsumerUnitPercentual,
    ]
  );

  const HAS_BILLING_INFO = useMemo(
    () => [
      {
        key: 'Identificador da fatura',
        value: `RE-${ALPHA_NUMERIC(
          consumerUnitPowerDistributionUnitIdentifier
        )}-${ALPHA_NUMERIC(
          formatDateToLocaleDateString(billReferenceDate as string, {
            year: '2-digit',
            month: '2-digit',
          })
        )}`,
      },
      {
        key: 'Mês de referência',
        value: getMonthAndYear({
          spliter: ' de ',
          options: {
            month: 'long',
            timeZone: 'UTC',
          },
          date: billReferenceDate as string,
        }),
      },
      {
        key: 'Bandeira tarifária',
        value: getTariffFlagModalityFlag()?.flag,
      },
    ],
    [
      getMonthAndYear,
      billReferenceDate,
      getTariffFlagModalityFlag,
      consumerUnitPowerDistributionUnitIdentifier,
    ]
  );

  const emissionFactor = 0.08358;
  const tc02 = (
    Number(billConsumedEnergyCredits.consumedEnergyCreditsTotal) *
    (emissionFactor / 100)
  ).toFixed(2);
  const trees = Math.round((Number(tc02) * 12) / 0.0134 / 12);

  return (
    <div
      id="economy-report-first-page"
      className="flex flex-col items-center justify-start h-auto py-10 px-10 gap-6 bg-white font-montserrat relative"
      style={{ width: '49rem' }}
    >
      <header className="w-full flex items-center justify-between pb-2">
        <h1 className="font-bold text-gray-dark51 text-2xl tracking-wider-1">
          RELATÓRIO DE ECONOMIA
        </h1>
        <NexLogo />
      </header>
      <section className="flex w-full items-start justify-between gap-3">
        <div
          id="economy-report-billing-info"
          className="flex flex-col items-start justify-start w-full border border-gray border-solid justify-center py-3 px-8 rounded-lg text-gray-dark130 text-xs tracking-wider-1 h-auto gap-1"
        >
          <h3
            className="font-semibold"
            style={{
              fontSize: '1rem',
              lineHeight: '1.5rem',
            }}
          >
            Razão Social
          </h3>
          {HAS_CONSUMER_UNIT_INFO.map((item) => (
            <p key={item.key} className="font-normal">
              {item.key}: <span className="font-semibold">{item.value || '-'}</span>
            </p>
          ))}
        </div>
        <div
          id="economy-report-billing-info-v2"
          className={classNames(
            'flex flex-col bg-gray-dark200 w-full border border-gray border-solid py-4 px-8 rounded-lg border-l-3 text-xs text-gray-dark130 justify-center h-auto gap-1',
            {
              [getTariffFlagModalityFlag()?.color as string]:
                !!getTariffFlagModalityFlag(),
            }
          )}
        >
          {HAS_BILLING_INFO.map((item) => (
            <div className="flex flex-row" key={item.key}>
              <p className="font-normal pr-1">{item.key}: </p>
              <span className="font-semibold">{item.value || '-'}</span>
            </div>
          ))}
        </div>
      </section>
      <section className="flex flex-row w-full gap-6 pl-2">
        <EconomyReportGenerationImage />
        <div className="flex flex-col w-full border border-gray border-solid rounded-lg justify-center pl-18 gap-1">
          <h1
            id="economy-report-generation-unit-name"
            className="font-semibold pb-2 leading-6 text-gray-dark51"
            style={{
              fontSize: '1.4rem',
            }}
          >
            {generationUnitLegalName}
          </h1>
          {HAS_GENERATION_UNIT_INFO.map((item, i) => {
            const index = i === 2;

            return (
              <div
                key={item.key}
                className={classNames('flex text-sm text-gray-dark130 gap-1', {
                  'flex-row': !index,
                  'flex-col': index,
                })}
              >
                <h3 className="font-normal">{item.key}</h3>
                <p className="font-semibold">{item.value}</p>
              </div>
            );
          })}
        </div>
      </section>
      <section className="flex flex-col gap-3 w-full">
        <div className="flex flex-col items-start mt-1 mb-1">
          <h1 className="text-gray-dark-51 font-semibold text-md">
            Entenda a sua economia NEX:
          </h1>
          <p className="text-gray-dark130 font-normal tracking-wider-1 text-sm">
            *Esses valores são cálculos aproximados
          </p>
        </div>
        <div className="flex flex-row justify-center gap-3">
          <div className="w-full border border-gray border-solid rounded-tl-lg rounded-tr-lg">
            <div className="flex flex-row items-center p-5 pl-8 border-b border-gray border-solid">
              <h3 className="font-semibold text-gray-dark130">Sem a NEX</h3>
            </div>
            <div
              className="flex flex-col justify-center items-center bg-gray-dark200 text-gray-dark130 font-normal tracking-wider-1 text-sm"
              style={{
                height: '210px',
              }}
            >
              <p>Fatura da concessionária</p>
              <p className="font-bold text-3xl">
                {convertToBRL(
                  String(
                    Number(chargeTotalCharged) +
                      Number(billTotalValue) +
                      Number(economyValue)
                  )
                )}
              </p>
            </div>
          </div>
          <div className="w-full border border-gray border-solid rounded-tl-lg rounded-tr-lg">
            <div className="flex flex-row items-center p-5 pl-8 border-b border-gray border-solid">
              <h3 className="font-semibold text-gray-dark130">Com a NEX</h3>
            </div>
            <div
              className="flex flex-col justify-center items-center bg-gray-dark200 text-gray-dark130 font-normal tracking-wider-1 text-sm"
              style={{
                height: '210px',
              }}
            >
              <p className="font-bold text-3xl">
                {convertToBRL(
                  String(Number(chargeTotalCharged) + Number(billTotalValue))
                )}
              </p>
              <p>
                Concessionária:{' '}
                <strong className="font-bold">{convertToBRL(billTotalValue)}</strong>
              </p>
              <p>
                Boleto NEX:{' '}
                <strong className="font-bold">
                  {convertToBRL(chargeTotalCharged)}
                </strong>
              </p>
            </div>
          </div>
          <div className="w-full border border-gray border-solid rounded-tl-lg rounded-tr-lg">
            <div className="flex flex-row items-center p-5 pl-8 border-b border-gray border-solid">
              <h3 className="font-semibold text-gray-dark130">Sua economia NEX</h3>
            </div>
            <div
              className="flex flex-col justify-center items-center text-center bg-primary text-gray-dark51 font-normal tracking-wider-1 text-sm"
              style={{
                height: '210px',
              }}
            >
              <p>
                Você teve uma <br></br>economia de
              </p>
              <p className="font-bold text-3xl">{convertToBRL(economyValue)}</p>
            </div>
          </div>
        </div>
        <div
          id="economy-report-notice-message"
          className="flex flex-row items-center text-sm text-gray-dark130 justify-center w-full border border-gray border-solid p-4 h-auto"
        >
          <p className="font-semibold pr-1">Atenção:</p>
          <p className="font-regular">
            Com a NEX você paga duas faturas, mas paga menos no valor total.
          </p>
        </div>
      </section>
      <section className="flex flex-col gap-4 w-full">
        <h3 className="text-gray-dark51 font-medium text-lg">
          Seu histórico de economia e seus impactos:
        </h3>
        <div className="flex flex-row justify-between gap-3">
          <div
            className="border border-gray border-solid text-xs h-full text-gray-dark130"
            style={{ width: '120%' }}
          >
            <div>
              {economyReports &&
                economyReports.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between py-2 px-8 border-gray border-solid border-b economy-report-bill"
                  >
                    <p>
                      {getMonthAndYear({
                        date: item.economyReferenceDate,
                        options: {
                          month: 'short',
                          timeZone: 'UTC',
                        },
                        spliter: '/',
                      }).toUpperCase()}
                      :
                    </p>
                    <p className="font-bold text-gray-dark130">
                      {convertToBRL(item.economyValue)}
                    </p>
                  </div>
                ))}
            </div>
            <div
              id="economy-report-bill-total"
              className="flex justify-center py-3 px-5 items-center border-b border-gray border-solid bg-gray-dark200"
            >
              <p>
                ECON. EM 6 MESES:{' '}
                <strong className="text-gray-dark130 font-bold">
                  {convertToBRL(
                    String(
                      economyReports?.reduce(
                        (acc, item) => acc + +item.economyValue,
                        0
                      )
                    )
                  )}{' '}
                </strong>
              </p>
            </div>
            <div
              id="economy-report-bill-total-v2"
              className="flex justify-center py-3 px-5 items-center bg-primary"
            >
              <p>
                TOTAL ECON. NEX:{' '}
                <strong className="text-gray-dark51 font-bold">
                  {convertToBRL(totalEconomyValue)}
                </strong>
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col items-center justify-center text-center border border-gray border-solid rounded-lg p-4">
            <EconomyReportTree />
            <p className="text-gray-dark130 font-medium mt-8">Você plantou</p>
            <p className="text-gray-dark51 font-bold text-3xl">{trees}</p>
          </div>
          <div className="w-full flex flex-col items-center justify-center text-center border border-gray border-solid rounded-lg p-4">
            <EconomyReportGenerationCo2 />
            <p className="text-gray-dark130 font-medium mt-8">
              Deixou de emitir <br></br> co₂ na atmosfera
            </p>
            <p className="text-gray-dark51 font-bold text-3xl">
              {convertToDecimal(tc02)} tCO₂
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
